import React, {useContext} from "react";
import "./componentStyle.scss";
import {routes, GeneralContext} from "../contexts/GeneralContext";
import {language} from "../shared-components/content/language";
import StyledButton from "../shared-components/generic/StyledButton";
import {Utility} from "../shared-components/Utility";
import {useNavigate} from 'react-router-dom';
import CoverImageViewer from "./CoverImageViewer";

const LandingPage = () => {
    const {
        appLang,
        eventId,
        eventName,
        eventColor,
        welcomeMsg,
    } = useContext(GeneralContext);
    const content = language[appLang];
    const navigate = useNavigate();

    return (
            <div className="landing-page">
                <CoverImageViewer/>
                <div className="main-container">
                    <div className="big-text">
                        {welcomeMsg
                            ? welcomeMsg
                            : Utility.createVariableSentenceSpan(Utility.capitalize(content.WELCOME_TO_$$EVENT_NAME$$), new Map([["eventName", eventName]]))
                        }
                    </div>
                    <div className="small-text">
                        <div>{Utility.capitalize(content.CAPTURE_AND_SHARE_YOUR_MOMENT)}</div>
                        <div>{Utility.capitalize(content.VIEW_OTHERS_MOMENTS_IN_A_LIVE_FEED)}</div>
                        {/*<div>{Utility.capitalize(content.ENTER_A_RAFFLE_TO_WIN_SWAG)}</div>*/}
                    </div>
                    <div className="bottom-section">
                        <StyledButton
                            brandColorOverride={eventColor}
                            type="main"
                            onClick={() => navigate(routes.UPLOAD + "/?id=" + eventId)}
                        >
                            {Utility.capitalize(content.START_SHARING)}
                        </StyledButton>
                    </div>
                </div>
            </div>
    );
};

export default LandingPage;